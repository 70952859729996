import React, { Component } from 'react';
import { MDBDataTable } from "mdbreact";
import { Container, Card, CardHeader, CardBody, Row, Col, Alert, Label, Modal, ModalHeader, ModalBody, ModalFooter, Button, FormGroup, Spinner } from "reactstrap";
import axios from 'axios'
import moment, { now } from 'moment-business-days'
import { Link } from "react-router-dom";
// import "./single-customer.css"

import { AvForm, AvField } from "availity-reactstrap-validation";
//SweetAlert
import SweetAlert from "react-bootstrap-sweetalert";

//Import Breadcrumb
// import Breadcrumbs from '../../components/Common/Breadcrumb';
import Badge from 'reactstrap/lib/Badge';
// import CardHeader from 'reactstrap/lib/CardHeader';

import companyData from './../../config/company'

const PACKAGES_API = process.env['REACT_APP_' + companyData.COMPANY_SHORT_NAME + '_PACKAGES_API']
const CUSTOMERS_API = process.env['REACT_APP_' + companyData.COMPANY_SHORT_NAME + '_CUSTOMERS_API']
const CUSTOMER_ENDPOINT = process.env.REACT_APP_CUSTOMER_ENDPOINT

class SingleCustomer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            // breadcrumbItems: [
            //     { title: "Giantz PMS", link: "#" },
            //     { title: "Customer", link: "#" },
            //     { title: "Ricardo Hood" },
            // ],
            isAlertOpen: false,
            showEditCustomerModal: false,
            showNewPackageModal: false,
            showManagePackageModal: false,
            loadingPage: true,
            showNoCustomerAlert: false,
            buttonDisabled: false,
            message: "",
            messageType: "",
            packagesData:
            {
                columns: [
                    {
                        label: "Tracking No.",
                        field: "trackingNo",
                        width: 300
                    },
                    {
                        label: "Origin",
                        field: "origin",
                        width: 100
                    },
                    {
                        label: "Description",
                        field: "description",
                        width: 100
                    },
                    {
                        label: "Date-Warehouse",
                        field: "dateAtWarehouse",
                        // sort: "desc",
                        width: 130
                    },
                    {
                        label: "Date-Jamaica",
                        field: "dateAtJamaica",
                        width: 130
                    },
                    {
                        label: "Date-Customer",
                        field: "dateAtCustomer",
                        width: 130
                    },
                    {
                        label: "Mode",
                        field: "deliveryMode",
                        width: 100
                    },
                    {
                        label: "Location",
                        field: "location",
                        width: 100
                    },
                    {
                        label: "Package Value",
                        field: "packageValue",
                        width: 70
                    },
                    {
                        label: "Shipping Cost",
                        field: "shippingCost",
                        width: 70
                    },
                    {
                        label: "Storage Fee",
                        field: "storageFee",
                        width: 70
                    },
                    {
                        label: "Actions",
                        field: "actions",
                        // width: 190
                    }
                ],
                rows: [

                ]
            },
            currentCustomer: {
                customerID: this.props.match.params.customerID,
                uniqueID: <Spinner size="sm" className="m-1" color="light"></Spinner>,
                fullName: <Spinner size="sm" className="m-1" color="light"></Spinner>,
                phoneNumber: <Spinner size="sm" className="m-1" color="light"></Spinner>,
                email: <Spinner size="sm" className="m-1" color="light"></Spinner>,

            },
            currentPackage: {

            },
            authUser: {
                displayName: JSON.parse(localStorage.getItem('userData')).displayName,
                role: JSON.parse(localStorage.getItem('userData')).role,
                warehouse: JSON.parse(localStorage.getItem('userData')).warehouse
            }
        }
        this.editCustomer.bind(this)
        this.loadCustomerData.bind(this)
        this.managePackage.bind(this)
        this.setCurrentPackage.bind(this)
    }

    componentDidMount() {
        document.body.style.zoom = "90%";
        this.loadCustomerData()
        // this.setState({
        //     authUser: {
        //         ...this.state.authUser,

        //     }
        // })
    }

    loadCustomerData() {
        let customerID = this.state.currentCustomer.customerID
        axios.get(PACKAGES_API + CUSTOMER_ENDPOINT + customerID)
            .then(response => {
                //CUSTOMER DATA
                let { uniqueID, firstName, lastName, phoneNumber, email, trn, address } = response.data.customer;
                let fullName = firstName + " " + lastName
                let loadedCustomerData = { uniqueID, customerID, fullName, firstName, lastName, phoneNumber, email, trn, address }
                //PACKAGES DATA
                let allPackagesData = response.data.customer.packages

                let formattedAllPackageData = allPackagesData.map(singlePackage => {
                    let { dateAtWarehouse, dateAtJamaica, dateAtCustomer, location, packageValue, shippingCost, storageFee } = singlePackage

                    let tDateAtWarehouse, tDateAtJamaica, tDateAtCustomer;

                    if (dateAtWarehouse !== null) {
                        dateAtWarehouse = moment(dateAtWarehouse, "YYYY-MM-DD").format("YYYY-MM-DD")
                        tDateAtWarehouse = moment(dateAtWarehouse, "YYYY-MM-DD").format("ddd. MMM DD, YYYY")
                    } else {
                        dateAtWarehouse = ""
                        tDateAtWarehouse = ""
                    }
                    if (dateAtJamaica !== null) {
                        dateAtJamaica = moment(dateAtJamaica, "YYYY-MM-DD").format("YYYY-MM-DD")
                        tDateAtJamaica = moment(dateAtJamaica, "YYYY-MM-DD").format("ddd. MMM DD, YYYY")
                    } else {
                        dateAtJamaica = ""
                        tDateAtJamaica = ""
                    }
                    if (dateAtCustomer !== null) {
                        dateAtCustomer = moment(dateAtCustomer, "YYYY-MM-DD").format("YYYY-MM-DD")
                        tDateAtCustomer = moment(dateAtCustomer, "YYYY-MM-DD").format("ddd. MMM DD, YYYY")
                    } else {
                        dateAtCustomer = ""
                        tDateAtCustomer = ""
                    }
                    let olocation = location
                    let daysInStorage

                    switch (location) {
                        case "Miami":
                        case "Florida":
                        case "Fort Lauderdale":
                        case "Hollywood":
                            location = <h5><Badge color="danger">{location}</Badge></h5>
                            break;
                        case "In Transit":
                            location = <h5><Badge color="primary">{location}</Badge></h5>
                            break;
                        case "Church Street":
                        case "MoBay":
                            location = <h5><Badge color="info">{location}</Badge></h5>
                            daysInStorage = moment(now()).businessDiff(moment(dateAtJamaica))
                            // console.log(daysInStorage);
                            if (daysInStorage > 7) {
                                storageFee = (daysInStorage - 8) * companyData.DAILY_STORAGE_RATE
                            }
                            // console.log(storageFee);
                            break;
                        case "Customer":
                            location = <h5><Badge color="success">{location}</Badge></h5>
                            break;
                        default:
                            break;
                    }

                    // console.log("NOW: " + moment(now()).format("YYYY-MM-DD"));
                    // console.log("JAM: " + moment(dateAtJamaica).format("YYYY-MM-DD"));

                    // // daysInStorage = moment(now()).businessDiff(moment(dateAtJamaica))
                    // console.log("Storage Days: " + daysInStorage);
                    // console.log("Storage Rate: " + companyData.DAILY_STORAGE_RATE);

                    // let shippingFee = daysInStorage * companyData.DAILY_STORAGE_RATE
                    // console.log("Shipping Fee: " + shippingFee);

                    let formattedSinglePackage = {
                        ...singlePackage,
                        dateAtWarehouse: dateAtWarehouse,
                        dateAtJamaica: dateAtJamaica,
                        dateAtCustomer: dateAtCustomer,
                        storageFee: storageFee
                    }


                    let formattedSingleTablePackage = {
                        ...singlePackage,
                        location: location,
                        olocation,
                        dateAtWarehouse: tDateAtWarehouse,
                        dateAtJamaica: tDateAtJamaica,
                        dateAtCustomer: tDateAtCustomer,
                        packageValue: "J$ " + packageValue,
                        shippingCost: "J$ " + shippingCost,
                        storageFee: "J$ " + storageFee,
                        actions: <div> <UpdatePackageButton onClick={(e) => { this.managePackage(formattedSinglePackage, e) }} /></div>
                    }

                    return {
                        ...formattedSingleTablePackage
                    }
                })
                let packagesData = { ...this.state.packagesData, rows: formattedAllPackageData };
                this.setState({ currentCustomer: loadedCustomerData, packagesData: packagesData, loadingPage: false });
            })
            .catch(err => {
                this.setState({ showNoCustomerAlert: true })
                // console.log(err.message)
            });
    }

    editCustomer = (event, values) => {
        let customerID = this.props.match.params.customerID
        this.setState({
            buttonDisabled: true
        })
        values = { ...values, modifiedBy: this.state.authUser.displayName }
        // console.table(values)
        axios.patch(CUSTOMERS_API + customerID, values)
            .then(response => {
                let message = response.data.message
                // console.log(response.data)
                //show alert for success message
                this.setState({ message, isAlertOpen: true, messageType: "success" })
                //Update state for closing
                this.loadCustomerData();
                setTimeout(() => {
                    this.setState({
                        showEditCustomerModal: false,
                        buttonDisabled: false,
                        message: "",
                        isAlertOpen: false
                    });
                }, 2000);
            })
            .catch(err => {
                let message = JSON.stringify(err.message)
                this.setState({
                    isAlertOpen: true,
                    message,
                    messageType: "danger",
                    buttonDisabled: false
                })
                console.log(message)
            })
    }

    addPackage = (event, values) => {
        this.setState({ buttonDisabled: true })
        // console.log(values);
        let packageData = {
            customerID: this.state.currentCustomer.customerID,
            ...values,
            dateAtJamaica: "",
            dateAtCustomer: "",
            createdBy: this.state.authUser.displayName,
            modifiedBy: this.state.authUser.displayName,
            packageValue: 0,
            shippingCost: 0,
            storageFee: 0,
            warehouse: this.state.authUser.warehouse
        }
        // console.log(packageData);
        axios.post(PACKAGES_API, packageData)
            .then(response => {
                let message = response.data.message
                this.loadCustomerData()
                this.setState({ message, isAlertOpen: true, messageType: "success" })
                setTimeout(() => {
                    this.setState({
                        showNewPackageModal: false,
                        buttonDisabled: false,
                        message: "",
                        isAlertOpen: false
                    });
                }, 2000);
            })
            .catch(err => {
                let message = JSON.stringify(err.message)
                this.setState({
                    isAlertOpen: true,
                    message,
                    messageType: "danger",
                    buttonDisabled: false
                })
                // console.log(JSON.stringify(err))
            })
    }

    editPackage = (event, values) => {

        let packageID = this.state.currentPackage._id
        this.setState({ buttonDisabled: true })
        // console.log(values);

        switch (values.location) {
            case "Fort Lauderdale":
            case "Miami":
                values.dateAtJamaica = ""
                values.dateAtCustomer = ""
                break;
            case "In Transit":
                values.dateAtJamaica = ""
                values.dateAtCustomer = ""
                break;
            case "Church Street":
            case "MoBay":
                if (values.dateAtJamaica === "") {
                    values.dateAtJamaica = moment(now()).format("YYYY-MM-DD")
                }
                values.dateAtCustomer = ""
                break;
            case "Customer":
                if (values.dateAtCustomer === "") {
                    values.dateAtCustomer = moment(now()).format("YYYY-MM-DD")
                    if (values.dateAtJamaica === "") {
                        values.dateAtJamaica = moment(now()).format("YYYY-MM-DD")
                    }
                }
                break;
            default:
                break;
        }


        let packageData = {
            ...this.state.currentPackage,
            ...values
        }
        // console.log(packageData);
        axios.patch(PACKAGES_API + packageID, packageData)
            .then(response => {
                let message = response.data.message
                this.loadCustomerData()
                this.setState({ message, isAlertOpen: true, messageType: "success" })
                setTimeout(() => {
                    this.setState({
                        showManagePackageModal: false,
                        buttonDisabled: false,
                        message: "",
                        isAlertOpen: false
                    });
                }, 2000);
            })
            .catch(err => {
                let message = JSON.stringify(err.message)
                this.setState({
                    isAlertOpen: true,
                    message,
                    messageType: "danger",
                    buttonDisabled: false
                })
                // console.log(JSON.stringify(err))
            })
    }

    setCurrentPackage = (obj) => {
        let currentPackage = {
            ...this.state.currentPackage,
            ...obj
        }
        this.setState({
            currentPackage: currentPackage,
            showManagePackageModal: true
        })
    }

    managePackage(mePackage) {

        this.setCurrentPackage(mePackage)
        // console.log(this.state.currentPackage)
    }

    loadPackageLocations() {
        return companyData.PACKAGE_LOCATIONS.map((pLocation, key) => {
            return <option key={key}>{pLocation}</option>
        })
    }

    render() {
        return (
            <React.Fragment >
                <div className="page-content">
                    <Container fluid>
                        {/* <Breadcrumbs title="Customer Account" breadcrumbItems={this.state.breadcrumbItems} /> */}
                        <Row>
                            {/* CUSTOMER DETAILS */}
                            <Col lg={6}>
                                <Card>
                                    <CardHeader className="bg-primary"></CardHeader>
                                    <CardBody>
                                        <Row>
                                            <Col lg={7}>
                                                <span className="h1 text-primary"><i className="mdi mdi-card-account-details mr-2"></i>{this.state.currentCustomer.fullName}</span>
                                                <hr className="mt-1 mb-2" />
                                            </Col>
                                            <Col lg={5} className="text-right">
                                                <Link to="#" onClick={() => this.setState({ showEditCustomerModal: true, isAlertOpen: false })} className="btn btn-light btn-md waves-effect waves-light mr-2"><i className="mdi mdi-account-edit mr-1"></i> Edit Customer</Link>
                                                {/* <Button color="light" className="">Edit Customer</Button> */}
                                                <Link to="#" onClick={() => this.setState({ showNewPackageModal: true })} className="btn btn-primary waves-effect ml-2">
                                                    {/* <Button color="primary" > */}
                                                    <i className="mdi mdi-package-variant-closed mr-1"></i> Add Package
                                                    {/* </Button> */}
                                                </Link>
                                                {/* <Button color="primary" className="btn-md waves-effect waves-light mr-2">New Package</Button> */}
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col lg={3}>
                                                <div>
                                                    <span className="">Unique ID:</span>
                                                </div>
                                                <span className="mt-0 h4 text-primary">{this.state.currentCustomer.uniqueID}</span>
                                            </Col>
                                            <Col lg={5}>
                                                <div>
                                                    <span className="">Phone Number: </span><span className="font-size-14 font-weight-bold text-primary">{this.state.currentCustomer.phoneNumber}</span>
                                                </div>
                                                <div>
                                                    <span className="">Email Address: </span><span className="font-size-14 font-weight-bold text-primary">{this.state.currentCustomer.email}</span>
                                                </div>
                                            </Col>
                                            <Col>
                                                <div>
                                                    <span className="">TRN: </span><span className="font-size-14 font-weight-bold text-primary">{this.state.currentCustomer.trn}</span>
                                                </div>
                                                <div>
                                                    <span className="">Address: </span><span className="font-size-14 font-weight-bold text-primary">{this.state.currentCustomer.address}</span>
                                                </div>
                                            </Col>

                                        </Row>

                                    </CardBody>
                                </Card>
                            </Col>
                            {/* <Col lg={2}>
                                <Card>
                                    <CardHeader className="bg-primary text-light text-center">Packages Available for Pickup</CardHeader>
                                    <CardBody className="text-center">
                                        <h2>9</h2>
                                    </CardBody>
                                </Card>
                            </Col> */}
                            {/* <Col lg={2}>
                                <Card>
                                    <CardHeader className="bg-dark text-light text-center">Total Balance</CardHeader>
                                    <CardBody className="text-center">
                                        <h2>$1,700</h2>
                                    </CardBody>
                                </Card>
                            </Col> */}
                        </Row>
                        <Row>
                            <Col lg={12}>
                                <Card>
                                    {/* <CardHeader className="bg-primary"></CardHeader> */}
                                    <CardBody>
                                        <h4><i className="mdi mdi-package-variant-closed mr-1"></i>Packages</h4>
                                        <hr />
                                        <MDBDataTable responsive striped scrollY maxHeight='400px' paging autoWidth={false} fixed bordered order={['dateAtWarehouse', 'desc']} data={this.state.packagesData} />
                                    </CardBody>

                                </Card>
                            </Col>
                        </Row>
                        {/* LOADING PAGE MODAL */}
                        <Modal
                            isOpen={this.state.loadingPage}
                            toggle={this.loadingTog}
                            backdrop="static"
                            centered
                            size="sm">
                            <ModalBody className="text-center">
                                <Spinner className="m-1" color="primary"></Spinner>
                            </ModalBody>

                        </Modal>
                        {/* ADD NEW PACKAGE MODAL */}
                        <Modal
                            isOpen={this.state.showNewPackageModal}
                            toggle={this.tog_static}
                            backdrop="static"
                            centered
                            size="lg"
                        >
                            <ModalHeader toggle={() => this.setState({ showNewPackageModal: false })}>
                                <p className="h3 text-primary">{this.state.currentCustomer.fullName}</p>
                                <p className="h6">New Package</p>
                            </ModalHeader>
                            <ModalBody>
                                <AvForm onValidSubmit={this.addPackage}>
                                    <Row>
                                        <Col lg={12}>
                                            <Alert color={this.state.messageType} isOpen={this.state.isAlertOpen} toggle={() => this.setState({ isAlertOpen: false })}>
                                                {this.state.message}
                                            </Alert>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col lg={3}>
                                            <FormGroup>
                                                <AvField
                                                    name="dateAtWarehouse"
                                                    label="Date At Warehouse"
                                                    type="date"
                                                    validate={{ dateRange: { start: { value: -7, units: 'days', }, end: { value: 0, units: 'days', } } }}
                                                    className="form-control"
                                                    id="dateAtWarehouse"
                                                    placeholder="Enter Date"
                                                    helpMessage="When the package arrived at the warehouse"
                                                    required
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col lg={3}>
                                            <FormGroup>
                                                <AvField
                                                    name="origin"
                                                    label="Package Origin"
                                                    type="text"
                                                    className="form-control"
                                                    id="origin"
                                                    placeholder="Origin"
                                                    helpMessage="Company that the package is coming from"
                                                    required
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col lg={3}>
                                            <FormGroup>
                                                <AvField
                                                    name="deliveryMode"
                                                    label="Mode of Delivery"
                                                    type="select"
                                                    // validate={{ dateRange: { start: { value: -21, units: 'days', }, end: { value: 0, units: 'days', } } }}
                                                    className="form-control"
                                                    id="deliveryMode"
                                                    helpMessage="Mode of delivery to Jamaica"
                                                    required
                                                    // selected={this.state.currentPackage.location}
                                                    value="Air"
                                                >
                                                    <option>Air</option>
                                                    <option>Sea</option>
                                                    {/* <option>Warehouse</option> */}

                                                </AvField>

                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col lg={6}>
                                            <FormGroup>

                                                {/* <Label htmlFor="phoneNumber">Phone Number</Label> */}
                                                <AvField
                                                    name="description"
                                                    label="Package Description"
                                                    type="text"
                                                    className="form-control"
                                                    id="trackingNo"
                                                    placeholder="Enter Description"
                                                    helpMessage="Enter the description of the package"
                                                    required
                                                />

                                            </FormGroup>
                                        </Col>
                                        <Col lg={6}>
                                            <FormGroup>
                                                {/* <Label htmlFor="phoneNumber">Phone Number</Label> */}
                                                <AvField
                                                    name="trackingNo"
                                                    label="Tracking Number"
                                                    type="text"
                                                    className="form-control"
                                                    id="trackingNo"
                                                    placeholder="Enter Tracking Number"
                                                    helpMessage="You may scan the label"
                                                    required
                                                />

                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <ModalFooter>
                                        <Button type="button" color="light" disabled={this.state.buttonDisabled} onClick={() => this.setState({ showNewPackageModal: false, isAlertOpen: false, message: "" })}>Cancel</Button>
                                        <Button type="submit" color="primary" disabled={this.state.buttonDisabled}>Add Package</Button>
                                    </ModalFooter>
                                </AvForm>
                            </ModalBody>
                        </Modal>
                        {/* MANAGE PACKAGE MODAL */}
                        <Modal
                            isOpen={this.state.showManagePackageModal}
                            toggle={this.tog_static}
                            backdrop="static"
                            centered
                            size="lg"
                        >
                            <ModalHeader toggle={() => this.setState({ showManagePackageModal: false })} className="">
                                {/* <p className="h3 text-primary">{this.state.currentCustomer.fullName}</p> */}
                                <Row lg={12} >
                                    <Col lg={"auto"} className="mb-2 mr-2">
                                        <p className="h6 text-primary"><i className="mdi mdi-cube-send mr-2 mb-0"></i>Tracking No:</p>
                                        <p className="h5">{this.state.currentPackage.trackingNo}</p>
                                    </Col>
                                    <Col lg={"auto"} className="mb-2 mr-2">
                                        <p className="h6 text-primary"><i className="mdi mdi-factory mr-2"></i>Package Origin:</p>
                                        <p className="h5">{this.state.currentPackage.origin}</p>
                                    </Col>
                                    <Col lg={"auto"} className="mb-2 mr-2">
                                        <p className="h6 text-primary"><i className="mdi mdi-factory mr-2"></i>Package Location:</p>
                                        {this.state.currentPackage.location}
                                    </Col>
                                    {/* <Col lg={"auto"} className="mb-2">
                                        <p className="h6 text-primary"><i className="mdi mdi-factory mr-2"></i>Date in Miami:</p>
                                        <p className="h5">{this.state.currentPackage.dateAtWarehouse}</p>
                                    </Col> */}
                                </Row>

                            </ModalHeader>
                            <ModalBody>
                                <AvForm onValidSubmit={this.editPackage} model={this.state.currentPackage} >
                                    <Row>
                                        <Col lg={12}>
                                            <Alert color={this.state.messageType} isOpen={this.state.isAlertOpen} toggle={() => this.setState({ isAlertOpen: false })}>
                                                {this.state.message}
                                            </Alert>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col lg={3}>
                                            <FormGroup>
                                                <AvField
                                                    name="dateAtWarehouse"
                                                    label="Date At Warehouse"
                                                    type="date"
                                                    // validate={{ dateRange: { start: { value: -21, units: 'days', }, end: { value: 0, units: 'days', } } }}
                                                    className="form-control text-info"
                                                    id="dateAtWarehouse"
                                                    placeholder="Enter Date"
                                                    helpMessage="When the package arrived in Miami"
                                                    required
                                                    disabled
                                                // value={this.state.currentPackage.dateAtWarehouse}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col lg={3}>
                                            <FormGroup>
                                                <AvField
                                                    name="dateAtJamaica"
                                                    label="Date At Jamaica"
                                                    type="date"
                                                    // validate={{ dateRange: { start: { value: -21, units: 'days', }, end: { value: 0, units: 'days', } } }}
                                                    className="form-control text-info"
                                                    id="dateAtJamaica"
                                                    placeholder="Package not in Jamaica"
                                                    helpMessage="When the package arrived in Jamaica"
                                                    // required
                                                    disabled
                                                // value={this.state.currentPackage.dateAtJamaica}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col lg={3}>
                                            <FormGroup>
                                                <AvField
                                                    name="dateAtCustomer"
                                                    label="Date At Customer"
                                                    type="date"
                                                    // validate={{ dateRange: { start: { value: -21, units: 'days', }, end: { value: 0, units: 'days', } } }}
                                                    className="form-control text-info"
                                                    id="dateAtCustomer"
                                                    placeholder="Enter Date"
                                                    helpMessage="When the package was collected by Customer"
                                                    disabled
                                                // required={this.state.currentPackage.dateAtJamaica == null}
                                                // value={this.state.currentPackage.dateAtCustomer}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col lg={3}>
                                            <FormGroup>
                                                <AvField
                                                    name="deliveryMode"
                                                    label="Mode of Delivery"
                                                    type="select"
                                                    // validate={{ dateRange: { start: { value: -21, units: 'days', }, end: { value: 0, units: 'days', } } }}
                                                    className="form-control"
                                                    id="deliveryMode"
                                                    helpMessage="Mode of delivery to Jamaica"
                                                    required
                                                // selected={this.state.currentPackage.location}
                                                // value="Air"
                                                >
                                                    <option>Air</option>
                                                    <option>Sea</option>
                                                    {/* <option>Warehouse</option> */}

                                                </AvField>
                                            </FormGroup>
                                        </Col>
                                        {/* <Col lg={3}>
                                            <FormGroup>
                                                <AvField
                                                    name="origin"
                                                    label="Package Origin"
                                                    type="text"
                                                    className="form-control text-info"
                                                    id="origin"
                                                    placeholder="Origin"
                                                    value={this.state.currentPackage.origin}
                                                    helpMessage="Company that the package is coming from"
                                                    required
                                                    readOnly
                                                />
                                            </FormGroup>
                                        </Col> */}
                                        {/* <Col lg={6}>
                                            <FormGroup>
                                                <AvField
                                                    name="trackingNo"
                                                    label="Tracking Number"
                                                    type="text"
                                                    className="form-control"
                                                    id="trackingNo"
                                                    placeholder="Enter Tracking Number"
                                                    helpMessage="You may scan the label"
                                                    required
                                                />
                                            </FormGroup>
                                        </Col> */}
                                    </Row>
                                    <Row>
                                        <Col lg={3}>
                                            <FormGroup>
                                                <AvField
                                                    name="packageValue"
                                                    label="Value of Package"
                                                    type="number"
                                                    // validate={{ dateRange: { start: { value: -21, units: 'days', }, end: { value: 0, units: 'days', } } }}
                                                    min="0"
                                                    className="form-control"
                                                    id="packageValue"
                                                    placeholder="Enter Value of the Package"
                                                    helpMessage="Value of the Package"
                                                    required
                                                // value={this.state.currentPackage.value}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col lg={3}>
                                            <FormGroup>
                                                <AvField
                                                    name="shippingCost"
                                                    label="Cost of Shipment"
                                                    type="number"
                                                    min="0"
                                                    className="form-control"
                                                    id="shippingCost"
                                                    placeholder="Enter cost of the Shipment"
                                                    helpMessage="Cost of the Shipment"
                                                    required
                                                // value={this.state.currentPackage.value}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col lg={3}>
                                            <FormGroup>
                                                <AvField
                                                    name="storageFee"
                                                    label="Storage Fee"
                                                    type="number"
                                                    min="0"
                                                    className="form-control"
                                                    id="storageFee"
                                                    placeholder="Enter the storage fee"
                                                    helpMessage="Storage Free"
                                                // value={this.state.currentPackage.value}
                                                />
                                            </FormGroup>

                                        </Col>
                                        <Col lg={3}>
                                            <FormGroup>
                                                <AvField
                                                    name="location"
                                                    label="Package Location"
                                                    type="select"
                                                    // validate={{ dateRange: { start: { value: -21, units: 'days', }, end: { value: 0, units: 'days', } } }}
                                                    className="form-control"
                                                    id="location"
                                                    helpMessage="Location of the Package"
                                                    required
                                                // selected={this.state.currentPackage.location}
                                                // value={this.state.currentPackage.location}
                                                >
                                                    {this.loadPackageLocations()}
                                                </AvField>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col lg={6}>
                                            <FormGroup>
                                                <Label htmlFor="description">Package Description</Label>
                                                <AvField
                                                    name="description"
                                                    type="text"
                                                    className="form-control"
                                                    id="description"
                                                    placeholder="Enter description for the package."
                                                    // value={this.state.currentCustomer.uniqueID}
                                                    required
                                                />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <ModalFooter>
                                        <Button type="button" color="light" disabled={this.state.buttonDisabled} onClick={() => this.setState({ showManagePackageModal: false, isAlertOpen: false, message: "" })}>Cancel</Button>
                                        <Button type="submit" color="primary" disabled={this.state.buttonDisabled}>Update Package</Button>
                                    </ModalFooter>
                                </AvForm>
                            </ModalBody>
                        </Modal>
                        {/* EDIT CUSTOMER MODAL */}
                        <Modal
                            isOpen={this.state.showEditCustomerModal}
                            toggle={this.tog_static}
                            backdrop="static"
                            centered
                            size="md"
                        >
                            <ModalHeader toggle={() => this.setState({ showEditCustomerModal: false })}>
                                <p className="h3 text-primary"><i className="mdi mdi-card-account-details mr-2"></i>{this.state.currentCustomer.fullName}</p>
                                {/* <p className="h5">Profile</p> */}
                            </ModalHeader>
                            <ModalBody>
                                <AvForm onValidSubmit={this.editCustomer}>
                                    <Row>
                                        <Col lg={12}>
                                            <Alert color={this.state.messageType} isOpen={this.state.isAlertOpen} toggle={() => this.setState({ isAlertOpen: false })}>
                                                {this.state.message}
                                            </Alert>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col lg={4}>
                                            <FormGroup>
                                                <Label htmlFor="uniqueID">Unique ID</Label>
                                                <AvField
                                                    name="uniqueID"
                                                    type="text"
                                                    className="form-control"
                                                    id="uniqueID"
                                                    placeholder="Enter Unique ID"
                                                    value={this.state.currentCustomer.uniqueID}
                                                    required
                                                />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col lg={6}>
                                            <FormGroup>
                                                <Label htmlFor="firstName">First Name</Label>
                                                <AvField
                                                    name="firstName"
                                                    type="text"
                                                    className="form-control"
                                                    id="firstName"
                                                    placeholder="Enter First Name"
                                                    value={this.state.currentCustomer.firstName}
                                                    required
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col lg={6}>
                                            <FormGroup>
                                                <Label htmlFor="lastName">Last Name</Label>
                                                <AvField
                                                    name="lastName"
                                                    type="text"
                                                    className="form-control"
                                                    id="lastName"
                                                    placeholder="Enter  Last Name"
                                                    value={this.state.currentCustomer.lastName}
                                                    required
                                                />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col lg={8}>
                                            <FormGroup>
                                                <Label htmlFor="address">Address</Label>
                                                <AvField
                                                    name="address"
                                                    type="text"
                                                    className="form-control"
                                                    id="address"
                                                    placeholder="Enter Address"
                                                    value={this.state.currentCustomer.address}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col lg={4}>
                                            <FormGroup>
                                                <Label htmlFor="trn">TRN</Label>
                                                <AvField
                                                    name="trn"
                                                    type="text"
                                                    className="form-control"
                                                    id="trn"
                                                    placeholder="Enter TRN"
                                                    value={this.state.currentCustomer.trn}
                                                />
                                            </FormGroup>
                                        </Col>

                                        {/* <Col lg={4}>
                                             <FormGroup>
                                                <Label htmlFor="dateOfBirth">Date of Birth</Label>
                                                <AvField
                                                    name="dateOfBirth"
                                                    type="date"
                                                    className="form-control"
                                                    id="dateOfBirth"
                                                    placeholder="Enter DOB"
                                                />
                                            </FormGroup>
                                        </Col> */}
                                    </Row>
                                    <Row>
                                        <Col lg={6}>
                                            <FormGroup>
                                                <Label htmlFor="email">Email</Label>
                                                <AvField
                                                    name="email"
                                                    type="email"
                                                    className="form-control"
                                                    id="email"
                                                    placeholder="Enter Email"
                                                    value={this.state.currentCustomer.email}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col lg={6}>
                                            <FormGroup>
                                                <Label htmlFor="phoneNumber">Phone Number</Label>
                                                <AvField
                                                    name="phoneNumber"
                                                    type="number"
                                                    className="form-control"
                                                    id="phoneNumber"
                                                    placeholder="Enter Phone Number"
                                                    value={this.state.currentCustomer.phoneNumber}
                                                />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <ModalFooter>
                                        <Button type="button" color="light" onClick={() => this.setState({ showEditCustomerModal: false })} disabled={this.state.buttonDisabled}>Cancel</Button>
                                        <Button type="submit" color="primary" disabled={this.state.buttonDisabled}>Update</Button>
                                    </ModalFooter>

                                </AvForm>

                            </ModalBody>
                        </Modal>
                        {/* NO CUSTOMER SWEET ALERT */}
                        <SweetAlert
                            title="Not a valid customer"
                            danger
                            // showCancel
                            confirmBtnBsStyle="primary"
                            confirmBtnText="Return to dashboard"
                            // cancelBtnBsStyle="danger"
                            show={this.state.showNoCustomerAlert}
                            onConfirm={() => {
                                // const history = useHistory()
                                // history.push('/dashboard')
                                this.props.history.push('/dashboard')
                                // console.log(this.props.history);
                            }
                            }>
                        </SweetAlert>
                    </Container>
                </div>
            </React.Fragment >
        );
    }
}

function UpdatePackageButton(props) {
    return (
        <Link to="#" onClick={props.onClick} className="btn-sm btn-info waves-effect ml-2" >
            {/* <Button color="primary" > */}
            < i className="mdi mdi-package-variant mr-1" ></i > Manage Package
            {/* </Button> */}
        </Link >
    )

}

export default SingleCustomer;