import React, { Component } from 'react';
import { MDBDataTable } from "mdbreact";
import { Button, Container, Badge, Row, Col, Card, Alert, Label, Modal, ModalHeader, ModalBody, ModalFooter, FormGroup, Spinner } from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
//SweetAlert
import SweetAlert from "react-bootstrap-sweetalert";
import { Link } from "react-router-dom";
import axios from 'axios'
import moment from 'moment'
// import { Page, Text, View, Document, StyleSheet, ReactPDF, PDFDownloadLink, PDFViewer } from '@react-pdf/renderer'

//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';

import MiniWidgets from "./MiniWidgets";
import CardBody from 'reactstrap/lib/CardBody';
// import CardHeader from 'reactstrap/lib/CardHeader';
import companyData from './../../config/company'

const CUSTOMERS_API = process.env['REACT_APP_' + companyData.COMPANY_SHORT_NAME + '_CUSTOMERS_API']
const PACKAGES_API = process.env['REACT_APP_' + companyData.COMPANY_SHORT_NAME + '_PACKAGES_API']
const PACKAGES_W_CUSTOMERNAME_ENDPOINT = process.env.REACT_APP_PACKAGES_CUST_EP
const PACKAGES_DASH_COUNT = process.env.REACT_APP_PACKAGES_DASH_COUNT

class Dashboard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            // breadcrumbItems: [
            //     { title: "Giantz Package Managment", link: "#" },
            //     { title: "Dashboard", link: "#" },
            // ],
            isAlertOpen: false,
            modal_static: false,
            showNewPackageModal: false,
            showNoConnectionAlert: false,
            buttonDisabled: false,
            message: "",
            messageType: "",
            packagesLoaded: false,
            customersLoaded: false,
            dashboardDataLoaded: false,
            // customerActions: "",
            dashboardData: [
            ],
            customerTableData:
            {
                columns: [
                    {
                        label: "Unique ID",
                        field: "uniqueID",
                        sort: "asc",
                        width: 50
                    },
                    {
                        label: "First Name",
                        field: "firstName",
                        sort: "asc",
                        width: 130
                    },
                    {
                        label: "Last Name",
                        field: "lastName",
                        // sort: "asc",
                        width: 130
                    },
                    {
                        label: "Phone Number",
                        field: "phoneNumber",
                        sort: "disabled",
                        width: 100
                    },
                    {
                        label: "Actions",
                        field: "actions",
                        sort: "disabled",
                        width: 250
                    }
                ],// actions: <div><Button color="info" className="btn-sm waves-effect waves-light mr-2">View Account</Button><Button color="success" className="btn-sm waves-effect">Add Package</Button></div>
                rows: []
            },
            packagesTableData:
            {
                columns: [
                    {
                        label: "Tracking Number",
                        field: "trackingNo",
                        sort: "asc",
                        // width: 100
                    },
                    {
                        label: "Date-Warehouse",
                        field: "dateAtWarehouse",
                        sort: "asc",
                        // width: 100
                    },
                    {
                        label: "Origin",
                        field: "origin",
                        sort: "asc",
                        // width: 100
                    },
                    {
                        label: "Customer",
                        field: "customerName",
                        sort: "asc",
                        // width: 100
                    },
                    {
                        label: "Mode",
                        field: "deliveryMode",
                        sort: "asc",
                        // width: 100
                    },
                    {
                        label: "Location",
                        field: "location",
                        sort: "asc",
                        // width: 100
                    },
                    {
                        label: "Actions",
                        field: "actions",
                        // sort: "asc",
                        // width: 100
                    }

                ],
                rows: [

                ]
            },
            currentCustomer: {
                customerID: "",
                fullName: ""

            },
            authUser: {
                displayName: JSON.parse(localStorage.getItem('userData')).displayName,
                role: JSON.parse(localStorage.getItem("userData")).role,
                warehouse: JSON.parse(localStorage.getItem("userData")).warehouse
            }


        }

        this.addCustomer.bind(this);
        this.addPackage.bind(this)
        // this.loadCustomerTable.bind(this)
        // this.loadPackageTable.bind(this)
        // this.loadDashboardData(this)
        // this.ViewAccountButton.bind(this)
    }

    // componentWillMount() {
    //     if (localStorage.getItem("userData")) {
    //         this.setState({
    //             authUser: {
    //                 ...this.state.authUser
    //             }
    //         })

    //     }
    // }

    componentDidMount() {
        document.body.style.zoom = "90%";
        this.loadCustomerTable()
        this.loadPackageTable()
        this.loadDashboardData()

    }

    // componentDidUpdate() {

    //     console.log("updated");
    // }

    addCustomer = (event, values) => {

        this.setState({
            buttonDisabled: true
        })

        values = {
            ...values,
            packages: [],
            createdBy: this.state.authUser.displayName,
            modifiedBy: this.state.authUser.displayName
        }

        axios.post(CUSTOMERS_API, values)
            .then(response => {
                let message = response.data.message

                //show alert for success message
                this.setState({ message, isAlertOpen: true, messageType: "success" })
                //Update state for closing
                this.loadCustomerTable();
                setTimeout(() => {
                    this.setState({
                        modal_static: false,
                        buttonDisabled: false,
                        message: "",
                        isAlertOpen: false
                    });
                }, 2000);

            })
            .catch(err => {
                let message = JSON.stringify(err.message)
                this.setState({
                    isAlertOpen: true,
                    message,
                    messageType: "danger",
                    buttonDisabled: false
                })
                // console.log(JSON.stringify(err))
            })

        // let demoData = this.state.customerData.rows;

        //push data to the varible
        // demoData.push({ firstName: firstName, lastName: lastName, phoneNumber: phoneNumber })

        //update data state
        // this.setState({ data: demoData });

    }

    addPackage = (event, values) => {
        this.setState({ buttonDisabled: true })
        // console.log(this.state.currentCustomer);
        // console.log(values);

        let packageData = {
            customerID: this.state.currentCustomer.customerID,
            ...values,
            location: "",
            dateAtJamaica: "",
            dateAtCustomer: "",
            createdBy: this.state.authUser.displayName,
            modifiedBy: this.state.authUser.displayName,
            packageValue: 0,
            shippingCost: 0,
            storageFee: 0,
            warehouse: this.state.authUser.warehouse

        }
        // console.log(packageData);


        axios.post(PACKAGES_API, packageData)
            .then(response => {
                let message = response.data.message
                this.setState({ message, isAlertOpen: true, messageType: "success" })
                this.loadPackageTable()
                this.loadDashboardData()
                setTimeout(() => {
                    this.setState({
                        showNewPackageModal: false,
                        buttonDisabled: false,
                        message: "",
                        isAlertOpen: false
                    });
                }, 2000);


            })
            .catch(err => {
                let message = JSON.stringify(err.message)
                this.setState({
                    isAlertOpen: true,
                    message,
                    messageType: "danger",
                    buttonDisabled: false
                })
                // console.log(JSON.stringify(err))
            })
    }

    handleAddNewPackage = (obj) => {
        let currentCustomer = {
            ...this.state.currentCustomer,
            customerID: obj._id,
            fullName: obj.firstName + " " + obj.lastName,
        }
        this.setState({
            currentCustomer: currentCustomer,
            showNewPackageModal: true
        })
    }

    // handleGenerateManifest() {

    //     ReactPDF.render(<Manifest />, `${__dirname}/example.pdf`);
    // }

    loadDashboardData() {
        axios.get(PACKAGES_API + PACKAGES_DASH_COUNT, {
            params: {
                warehouse: this.state.authUser.warehouse
            }
        })
            .then(response => {
                let loadedDashboardData = response.data.map(obj =>
                (
                    {
                        icon: "ri-folders-line",
                        title: obj._id,
                        value: obj.count
                    }
                ))
                this.setState({ dashboardData: loadedDashboardData, dashboardDataLoaded: true })
                // console.log(loadedDashboardData);
            })
            .catch(err => {
                this.setState({ showNoConnectionAlert: true })
                console.log(err)
            })
    }

    loadCustomerTable() {
        // console.log(process.env.REACT_APP_CUSTOMERS_API);
        axios.get(CUSTOMERS_API)
            .then(response => {
                // console.log(response.data);
                let customerData = { ...this.state.customerTableData };
                let loadedCustomerData = response.data.map(obj =>
                (
                    {
                        ...obj, actions: <div>
                            <ViewAccountButton customerID={obj._id} role="Manager" />
                            <AddNewPackageButton onClick={(e) => this.handleAddNewPackage(obj, e)} />
                        </div>

                    }
                )
                )
                // console.log(loadedCustomerData);
                customerData = { ...customerData, rows: loadedCustomerData };
                this.setState({ customerTableData: customerData, customersLoaded: true });
            })
            .catch(err => {
                this.setState({ showNoConnectionAlert: true })
                console.log(err)
            });
    }

    loadPackageTable() {
        // console.log(PACKAGES_API + PACKAGES_W_CUSTOMERNAME_ENDPOINT);
        axios.get(PACKAGES_API + PACKAGES_W_CUSTOMERNAME_ENDPOINT,
            {
                params: {
                    warehouse: this.state.authUser.warehouse
                }
            })
            .then(response => {
                // console.log(response.data.packages);
                let packageData = { ...this.state.packagesTableData };
                //CUSTOMER DATA

                let loadedPackageData = response.data.packages.map(obj => {
                    // console.log(obj);
                    let { dateAtWarehouse, location } = obj
                    let tDateAtWarehouse = moment(dateAtWarehouse, "YYYY-MM-DD").format("ddd. MMM DD, YYYY")
                    let olocation = location
                    switch (location) {
                        case "Miami":
                        case "Fort Lauderdale":
                        case "Hollywood":
                            location = <h5><Badge color="danger">{location}</Badge></h5>
                            break;
                        case "In Transit":
                            location = <h5><Badge color="primary">{location}</Badge></h5>
                            break;
                        case "MoBay":
                        case "Church Street":
                            location = <h5><Badge color="info">{location}</Badge></h5>
                            break;
                        case "Customer":
                            location = <h5><Badge color="success">{location}</Badge></h5>
                            break;
                        default:
                            break;
                    }

                    return {
                        ...obj,
                        dateAtWarehouse: tDateAtWarehouse,
                        location: location,
                        olocation,
                        customerName: obj.customerID.firstName + " " + obj.customerID.lastName,
                        // actions: <div> <UpdatePackageButton onClick={(e) => { this.managePackage(obj, e) }} /></div>
                    }
                }

                )
                packageData = { ...packageData, rows: loadedPackageData };
                this.setState({ packagesTableData: packageData, packagesLoaded: true });
            })
            .catch(err => {
                this.setState({ showNoConnectionAlert: true })
                console.log(err)
            });
    }

    managePackage(mePackage) {

        this.setCurrentPackage(mePackage)
        // console.log(this.state.currentPackage)
    }
    setCurrentPackage = (obj) => {
        let currentPackage = {
            ...this.state.currentPackage,
            ...obj
        }
        this.setState({
            currentPackage: currentPackage,
            showManagePackageModal: true
        })

        console.log(this.state.currentPackage);
    }

    render() {

        // const manifestStyle = StyleSheet.create({
        //     page: {
        //         flexDirection: 'row',
        //         backgroundColor: '#f1f5f7'
        //     },
        //     section: {
        //         margin: 10,
        //         padding: 10,
        //         flexGrow: 1
        //     }
        // })


        // const Manifest = () => (
        //     <Document title='Giantz Shipping Manifest' author='CODExNETWORK IT SOLUTIONS' creator='CODExNETWORK IT SOLUTIONS' producer='CODExNETWORK IT SOLUTIONS'>
        //         <Page size='LETTER' style={manifestStyle.page}>
        //             <div className="page-content">
        //                 <Container fluid>
        //                     CODExNETWORK
        //                 </Container>
        //             </div>
        //         </Page>
        //     </Document>
        // )

        return (
            <React.Fragment>
                <div className="page-content">
                    <Container fluid>
                        <Breadcrumbs title="Dashboard" breadcrumbItems={this.state.breadcrumbItems} />
                        <Row>
                            {/* DASHBOARD DATA */}
                            <Col xl={12}>
                                <Row>

                                    {!this.state.dashboardDataLoaded ? <div className="text-center mx-auto my-5"><Spinner className="m-1" color="primary"></Spinner><p className="font-size-2">Loading Data...</p></div> : <MiniWidgets reports={this.state.dashboardData} />}

                                </Row>

                            </Col>
                        </Row>
                        {/* <Row>
                            <PDFViewer width='100%' height='800'>
                                <Manifest />
                            </PDFViewer>
                        </Row> */}
                        <Row>
                            {/* CUSTOMER TABLE */}
                            <Col xl={6}>
                                <Card>
                                    <CardBody>
                                        <Row>
                                            <Col xl={9}>
                                                <h4>Customers</h4>
                                            </Col>
                                            <Col xl={3}>
                                                <Link to="#" onClick={() => this.setState({ modal_static: true, isAlertOpen: false })} className="btn btn-secondary float-right py-1 px-2" disabled><i className="mdi mdi-account-plus"></i> Add Customer</Link>
                                            </Col>
                                        </Row>
                                        <hr />
                                        {!this.state.customersLoaded ? <div className="text-center"> <Spinner className="m-1" color="primary"></Spinner><p>Loading Customers...</p></div> : <MDBDataTable responsive scrollY striped autoWidth={false} entries={20} maxHeight='400px' bordered paging order={['firstName', 'asc']} data={this.state.customerTableData} />}

                                    </CardBody>
                                </Card>
                            </Col>
                            {/* PACKAGES TABLE */}
                            <Col xl={6}>
                                <Card>
                                    <CardBody>
                                        <Row>
                                            <Col xl={9}>
                                                <h4>Packages</h4>
                                            </Col>
                                        </Row>
                                        <hr />
                                        {!this.state.packagesLoaded ? <div className="text-center"> <Spinner className="m-1" color="primary"></Spinner> <p>Loading Packages...</p></div> : <MDBDataTable responsive autoWidth={false} entriesOptions={[20, 50, 100, 150]} entries={20} fixed striped scrollY maxHeight='400px' bordered paging data={this.state.packagesTableData} />}

                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                        {/* ADD NEW CUSTOMER MODAL */}
                        <Modal
                            isOpen={this.state.modal_static}
                            toggle={this.tog_static}
                            backdrop="static"
                            centered
                            size="md"
                        >
                            <ModalHeader toggle={() => this.setState({ modal_static: false, message: "", isAlertOpen: false })}>
                                Add New Customer Details
                            </ModalHeader>
                            <ModalBody>
                                <AvForm onValidSubmit={this.addCustomer}>
                                    <Row>
                                        <Col lg={12}>
                                            <Alert color={this.state.messageType} isOpen={this.state.isAlertOpen} toggle={() => this.setState({ message: "", isAlertOpen: false })}>
                                                {this.state.message}
                                            </Alert>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col lg={4}>
                                            <FormGroup>
                                                <Label htmlFor="uniqueID">Unique ID</Label>
                                                <AvField
                                                    name="uniqueID"
                                                    type="text"
                                                    className="form-control"
                                                    id="uniqueID"
                                                    placeholder="Enter Unique ID"

                                                />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col lg={6}>
                                            <FormGroup>
                                                <Label htmlFor="firstName">First Name</Label>
                                                <AvField
                                                    name="firstName"
                                                    type="text"
                                                    className="form-control"
                                                    id="firstName"
                                                    placeholder="Enter First Name"
                                                    required
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col lg={6}>
                                            <FormGroup>
                                                <Label htmlFor="lastName">Last Name</Label>
                                                <AvField
                                                    name="lastName"
                                                    type="text"
                                                    className="form-control"
                                                    id="lastName"
                                                    placeholder="Enter  Last Name"
                                                    required
                                                />
                                            </FormGroup>
                                        </Col>
                                        {/* <Col lg={4}>
                                             <FormGroup>
                                                <Label htmlFor="dateOfBirth">Date of Birth</Label>
                                                <AvField
                                                    name="dateOfBirth"
                                                    type="date"
                                                    className="form-control"
                                                    id="dateOfBirth"
                                                    placeholder="Enter DOB"
                                                />
                                            </FormGroup>
                                        </Col> */}
                                    </Row>
                                    <Row>
                                        <Col lg={8}>
                                            <FormGroup>
                                                <Label htmlFor="address">Address</Label>
                                                <AvField
                                                    name="address"
                                                    type="text"
                                                    className="form-control"
                                                    id="address"
                                                    placeholder="Enter Address"
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col lg={4}>
                                            <FormGroup>
                                                <Label htmlFor="trn">TRN</Label>
                                                <AvField
                                                    name="trn"
                                                    type="text"
                                                    className="form-control"
                                                    id="trn"
                                                    placeholder="Enter TRN"
                                                />
                                            </FormGroup>
                                        </Col>

                                        {/* <Col lg={4}>
                                             <FormGroup>
                                                <Label htmlFor="dateOfBirth">Date of Birth</Label>
                                                <AvField
                                                    name="dateOfBirth"
                                                    type="date"
                                                    className="form-control"
                                                    id="dateOfBirth"
                                                    placeholder="Enter DOB"
                                                />
                                            </FormGroup>
                                        </Col> */}
                                    </Row>
                                    <Row>
                                        <Col lg={6}>
                                            <FormGroup>
                                                <Label htmlFor="email">Email</Label>
                                                <AvField
                                                    name="email"
                                                    type="email"
                                                    className="form-control"
                                                    id="email"
                                                    placeholder="Enter Email"
                                                />
                                            </FormGroup>
                                        </Col>

                                        <Col lg={6}>
                                            <FormGroup>
                                                <Label htmlFor="phoneNumber">Phone Number</Label>
                                                <AvField
                                                    name="phoneNumber"
                                                    type="number"
                                                    className="form-control"
                                                    id="phoneNumber"
                                                    placeholder="Enter Phone Number"
                                                />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <ModalFooter>
                                        <Button type="button" color="light" disabled={this.state.buttonDisabled} onClick={() => this.setState({ modal_static: false })}>Cancel</Button>
                                        <Button type="submit" color="primary" disabled={this.state.buttonDisabled}>Add Customer</Button>
                                    </ModalFooter>

                                </AvForm>

                            </ModalBody>
                        </Modal>
                        {/* ADD NEW PACKAGE MODAL */}
                        <Modal
                            isOpen={this.state.showNewPackageModal}
                            toggle={this.tog_static}
                            backdrop="static"
                            centered
                            size="lg"
                        >
                            <ModalHeader toggle={() => this.setState({ showNewPackageModal: false })}>
                                <p className="h3 text-primary">{this.state.currentCustomer.fullName}</p>
                                <p className="h6">New Package</p>
                            </ModalHeader>
                            <ModalBody>
                                <AvForm onValidSubmit={this.addPackage}>
                                    <Row>
                                        <Col lg={12}>
                                            <Alert color={this.state.messageType} isOpen={this.state.isAlertOpen} toggle={() => this.setState({ isAlertOpen: false })}>
                                                {this.state.message}
                                            </Alert>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col lg={3}>
                                            <FormGroup>
                                                <AvField
                                                    name="dateAtWarehouse"
                                                    label="Date At Warehouse"
                                                    type="date"
                                                    validate={{ dateRange: { start: { value: -7, units: 'days', }, end: { value: 0, units: 'days', } } }}
                                                    className="form-control"
                                                    id="dateAtWarehouse"
                                                    placeholder="Enter Date"
                                                    helpMessage="When the package arrived at the warehouse"
                                                    required
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col lg={3}>
                                            <FormGroup>
                                                <AvField
                                                    name="origin"
                                                    label="Package Origin"
                                                    type="text"
                                                    className="form-control"
                                                    id="origin"
                                                    placeholder="Origin"
                                                    helpMessage="Company that the package is coming from"
                                                    required
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col lg={3}>
                                            <FormGroup>
                                                <AvField
                                                    name="deliveryMode"
                                                    label="Mode of Delivery"
                                                    type="select"
                                                    // validate={{ dateRange: { start: { value: -21, units: 'days', }, end: { value: 0, units: 'days', } } }}
                                                    className="form-control"
                                                    id="deliveryMode"
                                                    helpMessage="Mode of delivery to Jamaica"
                                                    required
                                                    // selected={this.state.currentPackage.location}
                                                    value="Sea"
                                                >
                                                    <option>Air</option>
                                                    <option>Sea</option>
                                                    {/* <option>Warehouse</option> */}

                                                </AvField>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col lg={6}>
                                            <FormGroup>

                                                {/* <Label htmlFor="phoneNumber">Phone Number</Label> */}
                                                <AvField
                                                    name="description"
                                                    label="Package Description"
                                                    type="text"
                                                    className="form-control"
                                                    id="trackingNo"
                                                    placeholder="Enter Description"
                                                    helpMessage="Enter the description of the package"

                                                />

                                            </FormGroup>
                                        </Col>
                                        <Col lg={6}>
                                            <FormGroup>

                                                {/* <Label htmlFor="phoneNumber">Phone Number</Label> */}
                                                <AvField
                                                    name="trackingNo"
                                                    label="Tracking Number"
                                                    type="text"
                                                    className="form-control"
                                                    id="trackingNo"
                                                    placeholder="Enter Tracking Number"
                                                    helpMessage="You may scan the label"
                                                    required
                                                />

                                            </FormGroup>
                                        </Col>

                                    </Row>
                                    <ModalFooter>
                                        <Button type="button" color="light" disabled={this.state.buttonDisabled} onClick={() => this.setState({ showNewPackageModal: false, isAlertOpen: false, message: "" })}>Cancel</Button>
                                        <Button type="submit" color="primary" disabled={this.state.buttonDisabled}>Add Package</Button>
                                    </ModalFooter>

                                </AvForm>

                            </ModalBody>
                        </Modal>
                        {/* NO CONNECTION SWEET ALERT */}
                        <SweetAlert
                            title="Oops! Cannot connect to server"
                            danger
                            // showCancel
                            confirmBtnBsStyle="primary"
                            confirmBtnText="Try again"
                            // cancelBtnBsStyle="danger"
                            show={this.state.showNoConnectionAlert}
                            onConfirm={() => {
                                // const history = useHistory()
                                // history.push('/dashboard')
                                this.setState({ showNoConnectionAlert: false })
                                this.loadDashboardData()
                                this.loadPackageTable()
                                this.loadCustomerTable()
                                //this.props.history.push('/dashboard')
                                // console.log(this.props.history);
                            }
                            }>
                        </SweetAlert>

                    </Container>
                </div>
            </React.Fragment >
        );
    }
}

function ViewAccountButton(props) {

    let userRole = JSON.parse(localStorage.getItem("userData")).role
    // console.log(props.role, userRole);
    if (props.role === userRole || userRole === "Developer") {
        return (
            <Link to={`/customer/${props.customerID}`} className="btn-sm btn-secondary waves-effect waves-light mr-2">
                {/* <Button color="light" > */}
                <i className="mdi mdi-card-account-details mr-1"></i>  View Account
                {/* </Button> */}
            </Link>

        )
    } return null


}

function AddNewPackageButton(props) {
    return (
        <Link to="#" onClick={props.onClick} className="btn-sm btn-primary waves-effect ml-2">
            {/* <Button color="primary" > */}
            <i className="mdi mdi-package-variant-closed mr-1"></i> Add Package
            {/* </Button> */}
        </Link>
    )
}

function UpdatePackageButton(props) {
    return (
        <Link to="#" onClick={props.onClick} className="btn-sm btn-info waves-effect ml-2" >
            {/* <Button color="primary" > */}
            < i className="mdi mdi-package-variant mr-1" ></i > Manage Package
            {/* </Button> */}
        </Link >
    )

}

export default Dashboard;