import React from "react";
import { Redirect } from "react-router-dom";

// Authentication related pages
import Login from "../pages/Authentication/Login";
import Logout from "../pages/Authentication/Logout";
// import Register from "../pages/Authentication/Register";
import ForgetPwd from "../pages/Authentication/ForgetPassword";
// import AuthLockScreen from "../pages/Authentication/AuthLockScreen";

// Dashboard
import Dashboard from "../pages/Dashboard/index";

//Reporting
import Manifest from "../pages/Manifest";
//Customers
// import Customers from "../pages/Customers/index"

//SingleCustomer
import SingleCustomer from "../pages/Customers/single-customer"

const authProtectedRoutes = [

	{ path: "/dashboard", component: Dashboard },
	// { path: "/customers", component: Customers },
	{ path: "/customer/:customerID", component: SingleCustomer },
	{ path: "/manifest", component: Manifest },

	// this route should be at the end of all other routes
	{ path: "/", exact: true, component: () => <Redirect to="/dashboard" /> }
];

const publicRoutes = [
	{ path: "/logout", component: Logout },
	{ path: "/login", component: Login },
	{ path: "/forgot-password", component: ForgetPwd },
	// { path: "/register", component: Register },
	// { path: "/auth-lock-screen", component: AuthLockScreen },
];

export { authProtectedRoutes, publicRoutes };