import React from "react";
import { Row, Col, Container } from "reactstrap";

import companyData from "../../config/company.json"
import pmsVersion from "../../../src/version.json"

const Footer = () => {
    return (
        <React.Fragment>
            <footer className="footer">
                <Container fluid>
                    <Row>
                        <Col sm={4}>
                            {new Date().getFullYear()} © <a href="https://codexnetworkja.com" target="_blank" rel="noopener noreferrer">CODExNETWORK IT SOLUTIONS</a>
                        </Col>
                        <Col sm={4}>
                            <div className="text-sm-center">LICENSED TO: <span className="font-weight-bold">{companyData.COMPANY_NAME}</span></div>
                        </Col>
                        <Col sm={4}>
                            <div className="text-sm-right d-none d-sm-block">
                                PACKAGE MANAGEMENT SYSTEM - <b>V{pmsVersion.APP_VERSION}</b>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </footer>
        </React.Fragment>
    );
};

export default Footer;
