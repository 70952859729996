import firebase from "firebase/compat/app";

// Add the Firebase products that you want to use
import "firebase/compat/auth";
import 'firebase/compat/database';
import "firebase/compat/firestore";

class FirebaseAuthBackend {
  constructor(firebaseConfig) {
    if (firebaseConfig) {
      // Initialize Firebase
      firebase.initializeApp(firebaseConfig);
      firebase.auth().onAuthStateChanged(user => {
        if (user) {
          // localStorage.setItem("authUser", JSON.stringify(user));
          localStorage.setItem("authUser", true);

        } else {
          localStorage.removeItem("authUser");
          localStorage.removeItem("userData");
        }
      });
    }
  }

  /**
   * Registers the user with given details
   */
  registerUser = (email, password, fullName, role) => {
    return new Promise((resolve, reject) => {
      firebase
        .auth()
        .createUserWithEmailAndPassword(email, password)
        .then(
          userData => {
            // console.log(userData);
            const docRef = firebase.firestore().collection('usersCollection').doc(userData.user.uid);
            docRef.set({
              displayName: fullName,
              email: email,
              role: role,
              createdBy: "Ricardo",
              dateCreated: firebase.firestore.Timestamp.now()
            })
            resolve(firebase.auth().currentUser);
            firebase.auth().signOut()
            // firebase.firestore()
            //   .collection("usersCollection")
            //   .add({
            //     uid: userData.user.uid,
            //     role: role,
            //     createdBy: "Ricardo",
            //     dateCreated: firebase.firestore.Timestamp.now()
            //   })
            //   .then(function (docRef) {
            //     resolve(firebase.auth().currentUser);
            //     // console.log("Document written with ID: ", docRef.id);
            //     firebase.auth().signOut()
            //   })
            //   .catch(function (error) {
            //     console.error("Error adding document: ", error);
            //   });
          },
          error => {
            reject(this._handleError(error));
          },
        );
    });
  };

  /**
   * Login user with given details
   */
  loginUser = (email, password) => {
    return new Promise((resolve, reject) => {
      firebase
        .auth()
        .signInWithEmailAndPassword(email, password)
        .then(
          userData => {
            (async () => {
              const docRef = firebase.firestore().collection('usersCollection').doc(userData.user.uid);
              const doc = await docRef.get()
              if (!doc.exists) {
                // console.log('No such user!');
              } else {
                localStorage.setItem("userData", JSON.stringify(doc.data()))
                // console.log(JSON.stringify(doc.data()));
                resolve(firebase.auth().currentUser)
              }
            })();
          },
          error => {
            reject(this._handleError(error));
          }
        );
    });
  };

  /**
   * forget Password user with given details
   */
  forgetPassword = email => {
    return new Promise((resolve, reject) => {
      firebase
        .auth()
        .sendPasswordResetEmail(email, {
          url: window.location.protocol + "//" + window.location.host + "/login"
        })
        .then(() => {
          resolve(true);
        })
        .catch(error => {
          reject(this._handleError(error));
        });
    });
  };

  /**
   * Logout the user
   */
  logout = () => {
    return new Promise((resolve, reject) => {
      firebase
        .auth()
        .signOut()
        .then(() => {
          resolve(true);
        })
        .catch(error => {
          reject(this._handleError(error));
        });
    });
  };

  // setLoggeedInUser = user => {
  //   localStorage.setItem("authUser", JSON.stringify(user));
  // };

  /**
   * Returns the authenticated user
   */
  getAuthenticatedUser = () => {
    if (!localStorage.getItem("authUser")) return null;
    return [JSON.parse(localStorage.getItem("authUser")), JSON.parse(localStorage.getItem("userData"))];
  };

  /**
   * Handle the error
   * @param {*} error
   */
  _handleError(error) {
    // var errorCode = error.code;
    var errorMessage = error.message;
    return errorMessage;
  }
}

let _fireBaseBackend = null;

/**
 * Initilize the backend
 * @param {*} config
 */
const initFirebaseBackend = config => {
  if (!_fireBaseBackend) {
    _fireBaseBackend = new FirebaseAuthBackend(config);
  }
  return _fireBaseBackend;
};

/**
 * Returns the firebase backend
 */
const getFirebaseBackend = () => {
  return _fireBaseBackend;
};

export { initFirebaseBackend, getFirebaseBackend };
